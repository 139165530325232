@use '@angular/material' as mat;
@include mat.core();

mat.$theme-ignore-duplication-warnings: true;

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    typography: (
      brand-family: 'Lato',
    ),
  )
);

$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$magenta-palette,
      tertiary: mat.$violet-palette,
    ),
    typography: (
      brand-family: 'Lato',
    ),
  )
);

.dark-theme {
  @include mat.all-component-themes($dark-theme);
  @include mat.color-variants-backwards-compatibility($dark-theme);
}

.light-theme {
  @include mat.all-component-themes($light-theme);
  @include mat.color-variants-backwards-compatibility($light-theme);
}

:root {
  // My stuff
  --color-action-blue: #0064d1;
  --color-border-background: #{map-get(mat.$m2-gray-palette, 100)};
  --color-border: #{map-get(mat.$m2-gray-palette, 300)};
  --color-error: var(--mat-form-field-error-text-color);
  --color-hover: #e8eaf6;
  --color-link: #0064d1;
  --color-snack-text: var(--color-border-background);
  --color-success: #{map-get(mat.$green-palette, 50)};
  --color-text: var(--mdc-dialog-supporting-text-color);
  --color-warn: #{map-get(mat.$orange-palette, 70)};
}
