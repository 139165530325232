/* debug stuff can be kept in the code with .none - use debxx so it's easily grep-able */
.debxx {
  border: 4px dotted hotpink;

  &.red {
    border: 4px solid red;
  }
  &.orange {
    border: 4px solid orange;
  }
  &.yellow {
    border: 4px solid yellow;
  }
  &.green {
    border: 4px solid green;
  }
  &.blue {
    border: 4px solid blue;
  }
  &.purple {
    border: 4px solid purple;
  }
  &.none {
    border: none !important;
  }
  &.dashed {
    border-style: dashed;
  }
  &.dotted {
    border-style: dotted;
  }
  &.solid {
    border-style: solid;
  }
}

h4 {
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 500 !important;

  &.no-margin {
    margin: 0;
  }

  &.back,
  &.heading {
    margin-bottom: 4px;
  }

  &.top {
    margin-top: 16px;
  }

  &.back {
    cursor: pointer;
    color: var(--mdc-filled-button-container-color);
    display: inline-flex;
    align-items: center;

    .mat-icon {
      font-size: 32px;
      line-height: 32px;
      width: 32px;
      height: 32px;
    }
  }
}

table.mat-mdc-table.generic-table {
  width: 100%;

  &.inside-dialog {
    margin: 0 -8px 8px 0;
  }

  tr {
    &.mat-mdc-row {
      line-height: 14px;
      height: 44px;
      font-size: 12px;

      &:hover {
        background: rgba(228, 237, 255, 0.5);

        td.color-link {
          text-decoration: underline;
          color: var(--color-link);
        }
      }

      &.scroll-to,
      &.active {
        background: rgb(228, 237, 255);
      }

      &.scroll-to {
        &.bolder {
          font-weight: bolder;
        }
      }
    }

    &.disabledRow {
      background: lightyellow;
      opacity: 0.8;

      td {
        color: rgba(0, 0, 0, 0.25);

        &.mat-column-action {
          font-size: 10px;
        }
      }

      .mat-column-action {
        width: 75px;
      }
    }

    th,
    td {
      padding-right: 4px;

      &.mat-column-actions,
      &.mat-column-action {
        width: 75px;
      }

      &.th-right,
      &.td-right {
        text-align: right;
      }

      &.td-right {
        padding-right: 24px; // it looks nicer to add padding b/c of sort arrows
      }

      &.th-center,
      &.td-center {
        text-align: center;
      }

      .mat-button,
      .mat-stroked-button,
      .mat-raised-button,
      .mat-flat-button {
        padding: 0 4px;
      }

      &.mat-column-select {
        width: 50px;
      }
    }

    th {
      &.th-right {
        .mat-sort-header-container {
          justify-content: flex-end;
        }
      }

      &.th-center {
        .mat-sort-header-container {
          justify-content: center;
        }
      }
    }

    td {
      .mono,
      &.mat-column-id {
        font-family: 'roboto mono', monospace;
        font-size: smaller;
        word-break: break-all;
      }

      &.color-link {
        cursor: pointer;
      }

      .mat-mdc-chip-set {
        .mat-mdc-chip {
          margin: 4px 0;
        }

        &.boolean .mat-mdc-chip {
          text-transform: uppercase;
        }

        .mat-mdc-chip + .mat-mdc-chip {
          margin-left: 4px;
        }
      }
    }

    &.mat-mdc-footer-row {
      td {
        // No rows found
        .empty {
          font-size: 18px;
          opacity: 0.5;
        }
      }
    }
  }
}

.mat-mdc-form-field {
  width: 100%;

  &.smaller-font,
  &.read-only {
    font-size: 14px;
    line-height: 1.125;

    .mdc-floating-label {
      font-size: inherit;
    }
  }

  &.read-only {
    height: 50px;
  }

  &.smaller-font {
    height: 70px;

    .mat-mdc-form-field-subscript-wrapper {
      height: 20px;
    }
  }

  &.no-gap .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  &.hidden-select .mat-mdc-text-field-wrapper {
    height: 0;
  }
}

.mat-mdc-chip {
  &.smaller-font,
  &.text-xs {
    .mat-mdc-chip-action-label {
      font-size: 12px;
    }
  }
}

.mat-mdc-autocomplete-panel,
.mdc-menu-surface.mat-mdc-select-panel {
  &.hidden-select {
    // margin-top: -24px;
  }

  @media screen and (min-height: 1080px) {
    &.select-300 {
      max-height: 300px !important;
    }

    &.select-600 {
      max-height: 600px !important;
    }

    &.select-800 {
      max-height: 800px !important;
    }

    &.select-1000 {
      max-height: 1000px !important;
    }
  }

  &.width-600 {
    min-width: 600px !important;
  }

  &.width-800 {
    min-width: 800px !important;
  }

  &.width-1000 {
    min-width: 1000px !important;
  }

  .mat-mdc-optgroup,
  .mat-mdc-option {
    &.smaller-font {
      font-size: 14px;
      height: 30px;
      line-height: 34px;
      min-height: 34px;
    }

    &.font-12 {
      font-size: 12px;
      height: 24px;
      line-height: 28px;
      min-height: 28px;
    }

    &.nowrap {
      .mdc-list-item__primary-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.is-flex {
      .mdc-list-item__primary-text {
        width: 100%;
      }
    }
  }
}

.mat-mdc-optgroup.smaller-font .mat-mdc-optgroup-label {
  min-height: 0;
  line-height: 30px;
}

.flex-important {
  display: flex !important;
}

.grid-important {
  display: grid !important;
}

.mat-icon {
  &.filled {
    font-variation-settings: 'FILL' 1;
  }

  &.large {
    font-size: 36px;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
}

.action-blue {
  color: var(--color-action-blue);
}

.transparent-dialog {
  opacity: 0.8;
}

// standard layout of what's laid out in the center
.router-outlet {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1rem;
  height: 100%;
}

.dark-theme {
  table.mat-mdc-table.generic-table {
    tr {
      &:hover {
        background: rgba(0, 0, 25, 0.5) !important;
      }
    }
  }
}

.mat-mdc-tab-group {
  &.mat-tab-margin .mat-mdc-tab-label-container {
    margin-bottom: 1rem;
  }

  &.mat-tab-100 {
    height: 100%;

    .mat-mdc-tab-body-wrapper {
      flex: 1 1 100%;
    }
  }
}

// Dialogs throw up unnecessary scroll bars w/o this. I think the bottom margin is causing this
.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  padding: 24px 20px 8px 20px !important;
}
