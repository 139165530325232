.mat-mdc-chip-set .mat-mdc-chip.bg {
  .mat-mdc-chip-action-label {
    color: white;
  }

  &.primary {
    background-color: var(--mdc-filled-button-container-color);
  }

  &.accent {
    background-color: var(--mdc-checkbox-selected-icon-color);
  }

  &.warn {
    background-color: var(--color-warn);
  }

  &.success {
    background-color: var(--color-success);
  }

  &.error {
    background-color: var(--color-error);
  }
}
